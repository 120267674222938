import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AppBar, Box, Button, Divider, IconButton, Toolbar, Typography } from '@material-ui/core';
import { Games as GamesIcon } from '@material-ui/icons';
import { CurrencyExchange as CurrencyExchangeIcon } from '@mui/icons-material';
import { ChevronDown as ChevronDownIcon } from '../icons/chevron-down';
import { useSettings } from '../contexts/settings-context';
import { Moon as MoonIcon } from '../icons/moon';
import { Sun as SunIcon } from '../icons/sun';
import { AccountPopover } from './account-popover';
import { Logo } from './logo';
import { DashboardNavbarMenu } from './dashboard-navbar-menu';
import { useAuth } from '../hooks/use-auth';
import { transactionsApi } from '../api/transactions';

export const DashboardNavbar = () => {
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { user, ledger, updateLedger, accessToken } = useAuth();
  const { settings, saveSettings } = useSettings();
  const [openMenu, setOpenMenu] = useState(false);
  const [darkMode, setDarkMode] = useState(settings.theme === 'dark');

  useEffect(async () => {
    try {
      const { data, pendingAmount } = await transactionsApi.getLedger(user.id, accessToken);
      // Set Cache
      if (data) {
        localStorage.setItem('gdcreate_balance', data.balance);
        // Pending Amount
        data.balance -= pendingAmount;
        // Update Ledger
        updateLedger(data);
      }
    } catch (err) {
      console.log(err);
    }
  }, [user]);

  const handleSwitchTheme = () => {
    saveSettings({
      ...settings,
      theme: settings.theme === 'light' ? 'dark' : 'light'
    });

    setDarkMode(settings.theme === 'light');
  };

  useEffect(() => {
    setDarkMode(settings.theme === 'dark');
  }, [settings]);

  // Get cache
  const balance = localStorage.getItem('gdcreate_balance');

  return (
    <AppBar
      elevation={0}
      sx={{ backgroundColor: 'neutral.100' }}
    >
      <Toolbar
        disableGutters
        sx={{
          alignItems: 'center',
          display: 'flex',
          minHeight: 64,
          px: 3,
          py: 1
        }}
      >
        <Box
          component={RouterLink}
          to="/dashboard"
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Logo
            emblemOnly
            variant={darkMode ? 'dark' : 'light'}
          />
        </Box>
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            borderColor: 'rgba(255,255,255,0.1)',
            mx: 3
          }}
        />
        <Box>
          <Typography
            color="textPrimary"
            fontSize="8px"
            fontWeight="bold"
            letterSpacing="1px"
            sx={{ textTransform: 'uppercase' }}
          >
            My tokens
          </Typography>
          <Box
            display="flex"
            alignContent="flex-center"
          >
            <CurrencyExchangeIcon
              sx={{
                width: '16px',
                mr: 1,
                lineHeight: '27px',
                color: darkMode ? 'primary.contrastText' : 'neutral.900'
              }}
            />
            <Typography
              color="primary.main"
              fontSize="18px"
              fontWeight="bold"
            >
              {ledger && ledger.balance ? ledger.balance : balance ?? 0}
            </Typography>
          </Box>
        </Box>
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            borderColor: 'rgba(255,255,255,0.1)',
            mx: 3
          }}
        />
        <Box>
          <Typography
            color="textPrimary"
            fontSize="8px"
            fontWeight="bold"
            letterSpacing="1px"
            sx={{ textTransform: 'uppercase' }}
          >
            My Referral Code
          </Typography>
          <Box
            display="flex"
            alignContent="flex-center"
          >
            <GamesIcon sx={{
              width: '16px',
              mr: 1,
              lineHeight: '27px',
              color: darkMode ? 'primary.contrastText' : 'neutral.900'
            }}
            />
            <Typography
              color="primary.main"
              fontSize="18px"
              fontWeight="bold"
            >
              {user && user['referral-code'] ? user['referral-code'] : 'NONE'}
            </Typography>
          </Box>
        </Box>
        <DashboardNavbarMenu
          onClose={() => setOpenMenu(false)}
          open={mdDown && openMenu}
        />
        <Button
          endIcon={(
            <ChevronDownIcon
              fontSize="small"
              sx={{
                ml: 2,
                transition: 'transform 250ms',
                transform: openMenu ? 'rotate(180deg)' : 'none'
              }}
            />
          )}
          onClick={() => setOpenMenu(true)}
          sx={{
            color: 'primary.contrastText',
            display: {
              md: 'none',
              xs: 'flex'
            }
          }}
          variant="text"
        >
          Menu
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          color="inherit"
          onClick={handleSwitchTheme}
          sx={{
            mx: 2,
            display: {
              md: 'inline-flex',
              xs: 'none'
            }
          }}
        >
          {darkMode
            ? <SunIcon />
            : <MoonIcon color="primary" />}
        </IconButton>
        <AccountPopover
          darkMode={darkMode}
          onSwitchTheme={handleSwitchTheme}
        />
      </Toolbar>
    </AppBar>
  );
};
