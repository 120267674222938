import { serverAxios as axios } from '../utils/axios';

class AuthApi {
  async login({ email }) {
    // POST REQUEST TO LOGIN
    try {
      const res = await axios.post('/gdcreate/auth/login', { email });
      return res.data;
    } catch (err) {
      console.log(err);
      throw err.response.data.error;
    }
  }

  async loginVerification({ email, code }) {
    try {
      // POST REQUEST TO LOGIN
      const res = await axios.post('/gdcreate/auth/login/verify', { email, code });
      return res.data;
    } catch (err) {
      throw err.response.data.error;
    }
  }

  async register({ email }) {
    try {
      // POST REQUEST TO LOGIN
      const res = await axios.post('/gdcreate/auth/register', { email });
      return res.data;
    } catch (err) {
      throw err.response.data.error;
    }
  }

  async registerVerification({ email, code }) {
    try {
      // POST REQUEST TO LOGIN
      const res = await axios.post('/gdcreate/auth/register/verify', { email, code });
      return res.data;
    } catch (err) {
      throw err.response.data.error;
    }
  }

  async logout(accessToken) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    // POST REQUEST TO LOGIN
    try {
      const res = await axios.delete('/gdcreate/auth/', config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async refreshToken(retry = false) {
    // POST REQUEST TO LOGIN
    try {
      const res = await axios.post('/gdcreate/auth/refresh_token', {}, { retry });
      return res.data;
    } catch (err) {
      throw err.response.data.error;
    }
  }
}

export const authApi = new AuthApi();
