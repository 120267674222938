import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../../hooks/use-auth';

export const UnRegisteredGuard = (props) => {
  const { children } = props;
  const { user } = useAuth();

  if (user['gd-create-id'] === '' || typeof user['gd-create-id'] === 'undefined' || user['gd-create-id'] === null) {
    // ADD GUARD TO THIS
    return <>{children}</>;
  }

  return (
    <Navigate to="/" />
  );
};

UnRegisteredGuard.propTypes = {
  children: PropTypes.node
};
