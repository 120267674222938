// import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { MainNavbar } from '../components/main-navbar';
// import { MainSidebar } from '../components/main-sidebar';
// import { Footer } from '../components/footer';
import { useEffect } from 'react';
import Menu from '../components/menu/menu';
import Footer from '../components/footer/footer';
import { useSettings } from '../contexts/settings-context';

const MainLayoutRoot = styled('div')(() => ({
  // paddingTop: 81
  paddingTop: 0
}));

export const MainLayout = ({ children }) => {
  // const [openSidebar, setOpenSidebar] = useState(false);
  // const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const { settings, setForceDark } = useSettings();
  useEffect(() => {
    setForceDark(true);
  }, []);

  if (settings.theme !== 'dark') return <></>;
  return (
    <MainLayoutRoot>
      <Menu />
      {/* <MainNavbar onOpenSidebar={() => setOpenSidebar(true)} /> */}
      {/* <MainSidebar
        onClose={() => setOpenSidebar(false)}
        open={lgDown && openSidebar}
      /> */}
      {children}
      <Footer />
    </MainLayoutRoot>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};
