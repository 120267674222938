import React from 'react';
import { Divider } from 'react-daisyui';

function Footer() {
  return (
    <div className="bg-black">
      <div className="container mx-auto font-inter py-[40px]">
        <div className="flex md:flex-row flex-col md:w-full w-11/12 mx-auto">
          <div className="md:w-2/3 w-full">
            <div className="flex">
              <p className="text-[0.85rem] font-bold text-white">Follow us on:</p>
            </div>
            <div className="flex mt-4">
              <a href="https://www.facebook.com/bombergrounds/">
                <img
                  src="static/icon-facebook.png"
                  className="w-[30px] h-[30px] mr-[15px]"
                  alt="Gigantic Duck"
                />
              </a>
              <a href="https://www.instagram.com/bombergrounds/">
                <img
                  src="static/icon-instagram.png"
                  className="w-[30px] h-[30px] mr-[15px]"
                  alt="Gigantic Duck"
                />
              </a>
              <a href="https://twitter.com/bombergrounds">
                <img
                  src="static/icon-twitter.png"
                  className="w-[30px] h-[30px] mr-[15px]"
                  alt="Gigantic Duck"
                />
              </a>
              <a href="https://www.youtube.com/bombergrounds">
                <img
                  src="static/icon-youtube.png"
                  className="w-[30px] h-[30px] mr-[15px]"
                  alt="Gigantic Duck"
                />
              </a>
            </div>
          </div>
          <div className="md:w-1/3 w-full flex md:justify-end md:mt-0 mt-10">
            <a
              href="https://apps.apple.com/us/app/bombergrounds-battle-royale/id1476733308"
              target="_blank"
              rel="noreferrer"
              className="group mr-2"
            >
              <img
                src="static/appstore-download.png"
                className="border rounded-md h-[50px]"
                alt="App Store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.Giganticduck.Bombergrounds"
              target="_blank"
              rel="noreferrer"
              className="group"
            >
              <img
                src="static/playstore-download.png"
                className="border rounded-md h-[50px]"
                alt="Play Store"
              />
            </a>
          </div>
        </div>
        <Divider className="my-[30px]" />
        <div className="flex md:w-full w-11/12 mx-auto">
          <div className="w-2/3">
            <div className="mb-[17px] md:flex-row flex-col md:gap-0 gap-2">
              <a
                href="https://giganticduck.com/investor-relations/"
                className="md:text-[13px] text-[15px] font-medium text-[#f1f1f1] hover:text-white md:inline-block block mr-8"
              >
                Investor Relations
              </a>
              <a
                href="https://giganticduck.com/careers/"
                className="md:text-[13px] text-[15px] font-medium text-[#f1f1f1] hover:text-white md:inline-block block mr-8"
              >
                Careers
              </a>
              {/* <a
                href="https://giganticduck.com/game-support/"
                className="md:text-[13px] text-[15px] font-medium
                text-[#f1f1f1] hover:text-white md:inline-block block mr-8"
              >
                Game Support
              </a> */}
              <a
                href="https://support.giganticduck.com/portal/"
                className="md:text-[13px] text-[15px] font-medium text-[#f1f1f1] hover:text-white md:inline-block block mr-8"
              >
                Game Support
              </a>
              <a
                href="https://giganticduck.com/privacy-policy/"
                className="md:text-[13px] text-[15px] font-medium text-[#f1f1f1] hover:text-white md:inline-block block mr-8"
              >
                Privacy Policy
              </a>
              <a
                href="https://giganticduck.com/terms-of-service/"
                className="md:text-[13px] text-[15px] font-medium text-[#f1f1f1] hover:text-white md:inline-block block mr-8"
              >
                Terms of Service
              </a>
              <a
                href="https://giganticduck.com/cookie-policy/"
                className="md:text-[13px] text-[15px] font-medium text-[#f1f1f1] hover:text-white md:inline-block block mr-8"
              >
                Cookie Policy
              </a>
              <a
                href="https://giganticduck.com/contact/"
                className="md:text-[13px] text-[15px] font-medium text-[#f1f1f1] hover:text-white md:inline-block block mr-8"
              >
                Contact
              </a>
            </div>
            <div className="text-[#666666]">
              <p className="text-[13px] font-medium mr-8">Gigantic Duck AB</p>
              <p className="text-[13px] font-medium mr-8">Borås, Sweden</p>
            </div>
          </div>
          <div className="flex justify-end w-1/3">
            <img
              src="static/giganticduck-logo.png"
              className="w-[63px] h-[63px] mt-[40px] self-end"
              alt="Gigantic Duck"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
