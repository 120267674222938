import { Suspense, lazy } from 'react';
import { AuthGuard } from './components/guards/auth-guard';
import { GuestGuard } from './components/guards/guest-guard';
import { UnRegisteredGuard } from './components/guards/unregistered-guard';
import { LoadingScreen } from './components/loading-screen';
import { DashboardLayout } from './containers/dashboard-layout';
import { MainLayout } from './containers/main-layout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Not found pages
const NotFound = Loadable(lazy(() => import('./containers/not-found').then((module) => ({ default: module.NotFound }))));

// Auth pages
const Home = Loadable(lazy(() => import('./containers/home').then((module) => ({ default: module.Home }))));

// Auth pages
const Login = Loadable(lazy(() => import('./containers/login').then((module) => ({ default: module.Login }))));
const PasswordRecovery = Loadable(lazy(() => import('./containers/password-recovery').then((module) => ({ default: module.PasswordRecovery }))));
const PasswordReset = Loadable(lazy(() => import('./containers/password-reset').then((module) => ({ default: module.PasswordReset }))));
// const Register = Loadable(lazy(() => import('./containers/register')
// .then((module) => ({ default: module.Register }))));
const VerifyCode = Loadable(lazy(() => import('./containers/verify-code').then((module) => ({ default: module.VerifyCode }))));

// Dashboard
const MainDashboard = Loadable(lazy(() => import('./containers/main-dashboard').then((module) => ({ default: module.MainDashboard }))));

// Earnings
const Earnings = Loadable(lazy(() => import('./containers/earnings').then((module) => ({ default: module.Earnings }))));

// Payout
const Payout = Loadable(lazy(() => import('./containers/payout').then((module) => ({ default: module.Payout }))));
const PayoutRequestCreate = Loadable(lazy(() => import('./containers/payout-request-create').then((module) => ({ default: module.PayoutRequestCreate }))));
const PayoutSettings = Loadable(lazy(() => import('./containers/payout-settings').then((module) => ({ default: module.PayoutSettings }))));

// Help
const Help = Loadable(lazy(() => import('./containers/help').then((module) => ({ default: module.Help }))));

// Settings
const Settings = Loadable(lazy(() => import('./containers/settings').then((module) => ({ default: module.Settings }))));

// Support
const Support = Loadable(lazy(() => import('./containers/support').then((module) => ({ default: module.Support }))));

// Account Request
const AccountRequest = Loadable(lazy(() => import('./containers/account-request').then((module) => ({ default: module.AccountRequest }))));
const AccountRequestPending = Loadable(lazy(() => import('./containers/account-request-pending').then((module) => ({ default: module.AccountRequestPending }))));

// SocialVerify
const SocialVerify = Loadable(lazy(() => import('./containers/social-verify').then((module) => ({ default: module.SocialVerify }))));

// OAuthRedirect
const OAuthRedirect = Loadable(lazy(() => import('./containers/oauth-redirect').then((module) => ({ default: module.OAuthRedirect }))));

const routes = [
  {
    path: '/',
    element: (
      <GuestGuard>
        <MainLayout>
          <Home />
        </MainLayout>
      </GuestGuard>
    )
  },
  {
    path: '/login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    )
  },
  // {
  //   path: 'register',
  //   element: (
  //     <GuestGuard>
  //       <Register />
  //     </GuestGuard>
  //   )
  // },
  {
    path: 'verify-code',
    element: (
      <GuestGuard>
        <VerifyCode />
      </GuestGuard>
    )
  },
  {
    path: 'password-recovery',
    element: (
      <GuestGuard>
        <PasswordRecovery />
      </GuestGuard>
    )
  },
  {
    path: 'password-reset',
    element: (
      <PasswordReset />
    )
  },
  {
    path: 'account-request',
    element: (
      <AuthGuard>
        <UnRegisteredGuard>
          <AccountRequest />
        </UnRegisteredGuard>
      </AuthGuard>
    )
  },
  {
    path: 'account-request-pending',
    element: (
      <AuthGuard>
        <UnRegisteredGuard>
          <AccountRequestPending />
        </UnRegisteredGuard>
      </AuthGuard>
    )
  },
  {
    path: 'oauth/redirect/:social',
    element: (
      <OAuthRedirect />
    )
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <MainDashboard />
      },
      {
        path: '/earnings',
        element: <Earnings />
      },
      {
        path: '/payout',
        children: [
          {
            path: '/',
            element: <Payout />
          },
          {
            path: 'create',
            element: <PayoutRequestCreate />
          },
          {
            path: 'settings',
            element: <PayoutSettings />
          }
        ]
      },
      {
        path: '/help',
        element: <Help />
      },
      {
        path: '/settings',
        element: <Settings />
      },
      {
        path: '/support',
        element: <Support />
      },
      {
        path: '/social',
        element: <SocialVerify />
      }
    ]
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default routes;
