import axios from 'axios';

const ServerApiOptions = {
  baseURL: process.env.REACT_APP_API_SERVER,
  withCredentials: true,
  params: {
    source: 'create',
    environmentIdentifier: process.env.REACT_APP_ENVIRONMENT_IDENTIFIER
  }
};

export const serverAxios = axios.create(ServerApiOptions);
