import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, FormHelperText, Grid, Typography } from '@material-ui/core';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { InputField } from '../input-field';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';

export const LoginJwt = () => {
  const mounted = useMounted();
  const { login, loginVerification } = useAuth();
  const [phase, setPhase] = useState(0);

  const formik = useFormik({
    initialValues: {
      email: '',
      code: '',
      submit: null
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      code: phase === 1 ? Yup.string().max(255).required('Code is required') : null,
      policy: Yup.boolean()
    }),
    onSubmit: async (values, helpers) => {
      try {
        if (phase === 0) {
          await login(values.email);
          // Set Phase
          setPhase(1);

          toast.success('Please check your email address and enter the code in.');
        } else {
          await loginVerification(values.email, values.code);

          if (mounted.current) {
            helpers.setStatus({ success: true });
            helpers.setSubmitting(false);
            toast.success('Successfully Logged in');
          }
        }
      } catch (err) {
        toast.error(err);
        // console.error(err);
        if (mounted.current) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          alignItems: 'center',
          mb: 3
        }}
      >
        <Typography
          color="textPrimary"
          variant="body1"
          align="center"
          sx={{ fontSize: '18px', fontWeight: '600' }}
        >
          Sign in with your Gigantic Duck ID
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
      </Box>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <InputField
            autoFocus
            error={Boolean(formik.touched.email && formik.errors.email)}
            fullWidth
            helperText={formik.touched.email && formik.errors.email}
            placeholder="Email address"
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="email"
            value={formik.values.email}
            pad={2}
          />
        </Grid>
        {
          phase === 1 ? (
            <Grid
              item
              xs={12}
            >
              <InputField
                error={Boolean(formik.touched.code && formik.errors.code)}
                fullWidth
                helperText={formik.touched.code && formik.errors.code}
                placeholder="Code"
                name="code"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.code}
                pad={2}
              />
            </Grid>
          ) : <></>
        }
        {formik.errors.submit && (
          <Grid
            item
            xs={12}
          >
            <FormHelperText error>
              {formik.errors.submit}
            </FormHelperText>
          </Grid>
        )}
        <Grid
          item
          xs={12}
        >
          <Button
            disabled={formik.isSubmitting}
            type="submit"
            color="primary"
            size="large"
            variant="contained"
            sx={{
              p: 2,
              fontSize: '12px',
              width: '100%',
              letterSpacing: '1px'
            }}
          >
            LOG IN NOW
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Button
            component="a"
            type="button"
            color="primary"
            size="large"
            variant="text"
            sx={{
              p: 2,
              fontSize: '12px',
              letterSpacing: '1px',
              textDecoration: 'underline',
              width: '50%',
              textAlign: 'center',
              margin: 'auto',
              display: 'block'
            }}
            href="https://giganticduck.com/privacy-policy/"
            target="_blank"
          >
            Privacy Policy
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Box>
            <Typography
              variant="body2"
              color="textPrimary"
              textAlign="center"
            >
              Back to&nbsp;
              <Link
                style={{
                  width: '50%',
                  textAlign: 'center',
                  margin: 'auto',
                  color: 'white',
                  fontWeight: 600,
                  textDecoration: 'none'
                }}
                to="/"
              >
                Home
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
