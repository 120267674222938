import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, CardContent, Container, Grid, Toolbar } from '@material-ui/core';
import { LoginJwt } from '../components/auth/login-jwt';
import { Logo } from '../components/logo';
import { useSettings } from '../contexts/settings-context';
import { useAuth } from '../hooks/use-auth';
import gtm from '../lib/gtm';

export const Login = () => {
  const { method } = useAuth();
  const { settings } = useSettings();

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Login | GD Create</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: '64px'
        }}
      >
        <Box sx={{ py: 10 }}>
          <Container
            maxWidth="md"
          >
            <Grid
              container
              justifyContent="center"
            >
              <Grid
                item
                justifyContent="center"
                md={6}
                sx={{
                  backgroundColor: 'neutral.100',
                  py: 6,
                  px: 0
                }}
              >
                <Container maxWidth="md">
                  <Toolbar
                    disableGutters
                    sx={{ height: 64 }}
                  >
                    <Box
                      sx={{ m: 'auto', display: 'block' }}
                    >
                      <RouterLink
                        to="/"
                      >
                        <Logo
                          variant={settings.theme === 'dark' ? 'light' : 'dark'}
                        />
                      </RouterLink>
                    </Box>
                  </Toolbar>

                  <Card
                    elevation={0}
                    sx={{
                      backgroundColor: 'neutral.100'
                    }}
                  >
                    <CardContent>
                      {method === 'JWT' ? <LoginJwt /> : <></>}
                    </CardContent>
                  </Card>
                </Container>
              </Grid>
            </Grid>
          </Container>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0
            }}
          >
            <img
              src="/static/Owl_Shaman_Small.png"
              alt="owl_shaman"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
