import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../../hooks/use-auth';

export const RegisteredGuard = (props) => {
  const { children } = props;
  const { user } = useAuth();
  const location = useLocation();

  if (user['gd-create-id'] === '' || typeof user['gd-create-id'] === 'undefined' || user['gd-create-id'] === null) {
    if (user.pending && location.pathname !== '/account-request-pending') {
      return (
        <Navigate to="/account-request-pending" />
      );
    }

    if (!user.pending && location.pathname !== '/account-request') {
      return (
        <Navigate to="/account-request" />
      );
    }
  }

  // ADD GUARD TO THIS
  return <>{children}</>;
};

RegisteredGuard.propTypes = {
  children: PropTypes.node
};
