import { serverAxios as axios } from '../utils/axios';

class TransactionsApi {
  async getLedger(accountId, accessToken) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };

    // GET REQUEST
    try {
      const res = await axios.get(`/gdcreate/account/${accountId}/ledger`, config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async getTransactions({ accountId, lastTransaction }, accessToken) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: {
        continuationToken: lastTransaction
      }
    };

    // GET REQUEST
    try {
      const res = await axios.get(`/gdcreate/account/${accountId}/transactions`, config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }
}

export const transactionsApi = new TransactionsApi();
