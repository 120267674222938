import PropTypes from 'prop-types';

export const Logo = (props) => {
  const { emblemOnly, variant, size } = props;

  let imageSize = size;

  if (!imageSize) imageSize = emblemOnly === true ? '36' : '240';
  let image = variant === 'light' ? '/static/gd-id-logo-small.png' : '/static/gd-id-logo-small.png';

  // if (emblemOnly) image = '/static/gdg-mei-logo-duck.png';
  if (emblemOnly) image = variant === 'light' ? '/static/create-logo-light.png' : '/static/create-logo-dark.png';

  return (
    <img
      src={image}
      width={imageSize}
      alt="GD Create Logo"
    />
  );
};

Logo.defaultProps = {
  emblemOnly: false,
  variant: 'dark'
};

Logo.propTypes = {
  emblemOnly: PropTypes.bool,
  variant: PropTypes.oneOf(['light', 'dark']),
  size: PropTypes.number
};
