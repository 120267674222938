import React, { useState } from 'react';
import { Button } from 'react-daisyui';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button as CoreButton, Typography } from '@material-ui/core';
import Sidebar from './sidebar';

function Menu() {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  return (
    <>
      <div className="fixed max-w-auto bg-black md:h-[80px] h-[65px] w-full z-10">
        <div className="md:max-w-[1600px] relative px-[25px] h-full flex flex-row mx-auto">
          <div className="mr-[64px] md:w-auto w-3/6 self-center">
            <img
              src="static/giganticduck-logo.png"
              className="md:w-[55px] md:h-[55px] w-[50px] h-[50px]"
              alt="Gigantic Duck"
            />
          </div>
          <div className="font-inter text-menugray md:flex hidden w-full">
            <div className="self-center font-bold text-[17px] leading-[80px] mr-[22px] group">
              <a
                href="https://giganticduck.com/games/"
                className="mr-[22px] group-hover:text-white transition-all"
              >
                Games
              </a>
              <div className="absolute top-[80px] left-0 bg-[rgba(10,10,10,0.8)] max-w-[1600px] w-full h-[80px] py-[25px] px-[20px] group-hover:block hidden flex opacity-0 group-hover:opacity-100">
                <div className="self-center font-bold text-[17px] leading-4 mr-[22px] py-[8px]">
                  <a
                    href="https://giganticduck.com/games/"
                    className="mr-[22px] hover:text-white transition-all px-[20px]"
                  >
                    Games
                  </a>
                </div>
              </div>
            </div>
            <div className="self-center font-semibold text-[17px] leading-4 mx-[22px]">
              <a
                href="https://giganticduck.com/careers/"
                className="mr-[22px] hover:text-white transition-all"
              >
                Careers
              </a>
            </div>
            <div className="self-center font-semibold text-[17px] leading-4 mx-[22px]">
              {/* <a
                href="https://giganticduck.com/game-support/"
                className="mr-[22px] hover:text-white transition-all"
              >
                Support
              </a> */}
              <a
                href="https://support.giganticduck.com/portal/"
                className="mr-[22px] hover:text-white transition-all"
              >
                Support
              </a>
            </div>
            <div className="self-center font-semibold text-[17px] leading-4 mx-[22px]">
              <a
                href="https://giganticduck.com/news/"
                className="mr-[22px] hover:text-white transition-all"
              >
                News
              </a>
            </div>
            <div className="self-center font-semibold leading-[80px] mx-[22px] group">
              <a
                href="/#"
                className="text-[30px] top-[-8px] relative block mr-[22px] hover:text-white transition-all"
              >
                . . .
              </a>
              <div className="flex justify-center absolute top-[80px] left-0 bg-[rgba(10,10,10,0.8)] w-full h-[80px] px-[20px] group-hover:flex hidden opacity-0 group-hover:opacity-100">
                <div className="self-center inline-block font-bold text-[17px] leading-4 mr-[22px]">
                  <a
                    href="https://giganticduck.com/investor-relations/"
                    className="mr-[22px] hover:text-white transition-all px-[20px]"
                  >
                    Investor Relations
                  </a>
                </div>
                <div className="self-center inline-block font-bold text-[17px] leading-4 mr-[22px]">
                  <a
                    href="https://giganticduck.com/cookie-policy/"
                    className="mr-[22px] hover:text-white transition-all px-[20px]"
                  >
                    Cookie Policy
                  </a>
                </div>
                <div className="self-center inline-block font-bold text-[17px] leading-4 mr-[22px]">
                  <a
                    href="https://giganticduck.com/privacy-policy/"
                    className="mr-[22px] hover:text-white transition-all px-[20px]"
                  >
                    Privacy Policy
                  </a>
                </div>
                <div className="self-center inline-block font-bold text-[17px] leading-4 mr-[22px]">
                  <a
                    href="https://giganticduck.com/terms-of-service/"
                    className="mr-[22px] hover:text-white transition-all px-[20px]"
                  >
                    Terms of Service
                  </a>
                </div>
              </div>
            </div>
            <div className="self-center font-semibold text-[17px] leading-4 mx-[22px] absolute right-0">
              <CoreButton
                component={RouterLink}
                to="/login"
                variant="contained"
                color="base"
                sx={{
                  position: 'relative',
                  top: 0,
                  transition: '0.1s ease-in',
                  '&:hover': {
                    top: '-4px'
                  },
                  p: '12px 30px',
                  borderRadius: '10px'
                }}
              >
                <Box
                  component="img"
                  src="/static/create-logo-dark-32.png"
                  alt="Apply as content creator cat taking notes"
                  sx={{
                    mr: 2,
                    height: '26px'
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    width: '100%',
                  }}
                  color="primary.contrastText"
                  variant="body1"
                >
                  LOG IN
                </Typography>
              </CoreButton>
            </div>
          </div>
          <div className="flex md:hidden block justify-end w-3/6">
            <Button
              variant="link"
              color="white"
              className="self-center text-white relative -right-2 p-0"
              onClick={toggleOpen}
            >
              {
                !open ? (
                  <svg
                    className="swap-off fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    width="42"
                    height="42"
                    viewBox="0 0 512 512"
                  >
                    <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
                  </svg>
                ) : (
                  <svg
                    className="swap-on fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    width="42"
                    height="42"
                    viewBox="0 0 512 512"
                  >
                    <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
                  </svg>
                )
              }
            </Button>
          </div>
        </div>
        <Sidebar open={open} />
      </div>
    </>
  );
}

export default Menu;
