import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authApi } from '../api/auth';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  accessToken: null,
  user: null,
  ledger: { balance: 0 }
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, accessToken, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      accessToken,
      user
    };
  },
  LOGIN: (state, action) => {
    const { userId } = action.payload;

    return {
      ...state,
      isAuthenticated: false,
      accessToken: null,
      user: { id: userId }
    };
  },
  AUTHENTICATE: (state, action) => {
    const { user, accessToken } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      accessToken,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    accessToken: null,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  SYNC: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      user: { ...state.user, ...user }
    };
  },
  LEDGER: (state, action) => {
    const { ledger } = action.payload;

    return {
      ...state,
      ledger: { ...state.ledger, ...ledger }
    };
  }
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

export const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => Promise.resolve(),
  login_verification: () => Promise.resolve(),
  register: () => Promise.resolve(),
  register_verification: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  syncUser: () => Promise.resolve(),
  updateLedger: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const refreshToken = async () => {
    try {
      const { user, accessToken } = await authApi.refreshToken();

      if (accessToken) {
        // Dispatch
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            accessToken,
            user
          }
        });

        // Loop Refresh
        setTimeout(() => {
          refreshToken();
        }, (300 * 1000) - 500); // 500ms before 580 seconds refresh
      } else { throw new Error('Failed to generate access token.'); }
    } catch (err) {
      if (err) console.log(err);

      // Dispatch
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          accessToken: null,
          user: null
        }
      });
    }
  };

  // Refresh Token
  useEffect(() => {
    refreshToken();
  }, []);

  const login = async (email) => {
    try {
      const { message } = await authApi.login({ email });

      return message;
    } catch (err) {
      console.log(err);
      // Dispatch
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          accessToken: null,
          user: null
        }
      });
      throw err;
    }
  };

  const loginVerification = async (email, code) => {
    try {
      const { user, accessToken } = await authApi.loginVerification({ email, code });

      if (accessToken) {
        dispatch({
          type: 'AUTHENTICATE',
          payload: {
            accessToken,
            user
          }
        });
      } else { throw new Error('Failed to generate access token.'); }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const register = async (email) => {
    try {
      const { message } = await authApi.register({ email });

      return message;
    } catch (err) {
      console.log(err);
      // Dispatch
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          accessToken: null,
          user: null
        }
      });
      throw err;
    }
  };

  const registerVerification = async (email, code) => {
    try {
      const { message } = await authApi.registerVerification({ email, code });
      return message;
    } catch (err) {
      console.log(err);
      // Dispatch
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          accessToken: null,
          user: null
        }
      });
      throw err;
    }
  };

  const logout = async (accessToken) => {
    try {
      await authApi.logout(accessToken);

      dispatch({ type: 'LOGOUT' });
    } catch (err) {
      console.log(err);
    }
  };

  const syncUser = (dataUser) => {
    if (!dataUser) return;

    const { id: userId } = dataUser;
    const { user } = state;
    // Check If ID matches the currently logged in user
    // Then update the data of the currently logged in
    if (userId === user.id) dispatch({ type: 'SYNC', payload: { user: dataUser } });
  };

  const updateLedger = (ledger) => {
    if (!ledger) return;

    dispatch({ type: 'LEDGER', payload: { ledger } });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        loginVerification,
        register,
        registerVerification,
        logout,
        syncUser,
        updateLedger
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
