import { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, IconButton, List } from '@material-ui/core';
import {
  AttachMoney as AttachMoneyIcon,
  ContactSupport as ContactSupportIcon,
  Payment as PaymentIcon,
  Dashboard as DashboardIcon
} from '@material-ui/icons';
import { DashboardSidebarItem } from './dashboard-sidebar-item';
import { Scrollbar } from './scrollbar';
import { ChevronLeft as ChevronLeftIcon } from '../icons/chevron-left';
import { ChevronRight as ChevronRightIcon } from '../icons/chevron-right';
// import { Cog as CogIcon } from '../icons/cog';
import { CustomUsers as UsersIcon } from '../icons/custom-users';

const items = [
  {
    icon: DashboardIcon,
    title: 'Dashboard',
    href: '/dashboard'
  },
  {
    icon: AttachMoneyIcon,
    title: 'Earnings',
    href: '/dashboard/earnings'
  },
  {
    icon: PaymentIcon,
    title: 'Payout',
    items: [
      {
        href: '/dashboard/payout',
        title: 'List'
      },
      {
        href: '/dashboard/payout/create',
        title: 'Payout Request'
      },
      {
        href: '/dashboard/payout/settings',
        title: 'Payment Settings'
      }
    ]
  },
  {
    icon: ContactSupportIcon,
    title: 'Help',
    href: '/dashboard/help'
  },
  // {
  //   icon: CogIcon,
  //   title: 'Settings',
  //   href: '/dashboard/settings'
  // },
  {
    icon: UsersIcon,
    title: 'Support',
    href: '/dashboard/support'
  }
];

export const DashboardSidebar = (props) => {
  const { onPin, pinned } = props;
  const { pathname } = useLocation();
  const [openedItems, setOpenedItems] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [activeHref, setActiveHref] = useState('');
  const [hovered, setHovered] = useState(false);

  const handleOpenItem = (item) => {
    if (openedItems.includes(item.title)) {
      setOpenedItems((i) => (i.filter((x) => x !== item.title)));
    } else {
      setOpenedItems((i) => [...i, item.title]);
    }
  };

  useEffect(() => {
    items.forEach((item) => {
      if (item.items) {
        for (let index = 0; index < item.items.length; index++) {
          const active = matchPath({ path: item.items[index].href, end: true }, pathname);

          if (active) {
            setActiveItem(item);
            setActiveHref(item.items[index].href);
            setOpenedItems((i) => [...i, item.title]);
            break;
          }
        }
      } else {
        const active = !!matchPath({ path: item.href, end: true }, pathname);

        if (active) {
          setActiveItem(item);
          setActiveHref(item.href);
          setOpenedItems((i) => [...i, item.title]);
        }
      }
    });
  }, [pathname]);

  return (
    <Drawer
      open
      sx={{ zIndex: 1000 }}
      variant="permanent"
      PaperProps={{
        onMouseOver: () => { setHovered(true); },
        onMouseLeave: () => { setHovered(false); },
        sx: {
          backgroundColor: 'background.paper',
          height: 'calc(100% - 64px)',
          overflowX: 'hidden',
          top: 64,
          transition: 'width 250ms ease-in-out',
          width: pinned ? 270 : 73,
          '& .simplebar-content': {
            height: '100%'
          },
          '&:hover': {
            width: 270,
            '& span, p': {
              display: 'flex'
            }
          }
        }
      }}
    >
      <Scrollbar
        style={{
          display: 'flex',
          flex: 1,
          overflowX: 'hidden',
          overflowY: 'auto'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            p: 2
          }}
        >
          <List disablePadding>
            {(items.map((item) => (
              <DashboardSidebarItem
                key={item.title}
                active={activeItem?.title === item.title}
                activeHref={activeHref}
                onOpen={() => handleOpenItem(item)}
                open={openedItems.includes(item.title) && (hovered || pinned)}
                pinned={pinned}
                {...item}
              />
            )))}
          </List>
          <Box sx={{ flexGrow: 1 }} />
          <Divider />
          <Box sx={{ pt: 1 }}>
            <IconButton onClick={onPin}>
              {pinned ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Box>
        </Box>
      </Scrollbar>
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onPin: PropTypes.func,
  pinned: PropTypes.bool
};
